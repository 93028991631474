class HtmlTemplate extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: "open" });
  }

  static get observedAttributes() {
    return ["contents"];
  }

  attributeChangedCallback(name, _, newValue) {
    if (name == "contents") {
      this.shadowRoot.innerHTML = newValue;
    }
  }
}

window.customElements.define("html-template", HtmlTemplate);
